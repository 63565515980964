import { FC } from 'react';

import { Link } from 'react-router-dom';

import { Document } from 'context/DocumentContext';
import FileDownloadButton from 'components/buttons/download/FileDownloadButton';
import { dateInPast, pastDateToRTF } from 'util/date/DateUtil';

const DocumentCard: FC<Document> = ({
    name, type, description,
    id, file,
    dueDate,
}) => {
    return <>
        <div className='p-shadow-3 p-mt-2 p-mb-3 p-px-2 p-py-1 p-grid'
            style={{ borderRadius: '3px', backgroundColor: 'var(--surface-50)' }}>
            <div className='p-col-8'>
                <Link to={`/document/${id}`} style={{ textDecoration: 'none', color: 'unset' }}>
                    <h4 className='p-mt-2 p-mb-1'>{name}</h4>
                    <h5 className='p-my-0'>{type}</h5>
                </Link>
                <p className='p-my-2'>{description}</p>
            </div>
            <div className='p-col-4'>
                <div className='p-d-flex p-jc-end p-ai-center' style={{ height: '100%' }}>
                    <FileDownloadButton className='p-ml-2' filePath={file} />
                </div>
            </div>
        </div>
    </>;
}

export default DocumentCard;