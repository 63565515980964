import { FC } from 'react';
import { Message } from 'primereact/message';

import { useAppContext } from 'context/AppContext';
import { useBrother } from 'context/BrotherContext';
import { Link } from 'react-router-dom';
import { MarginContainer } from 'components/layout/Container';

const DashboardAlerts: FC = () => {
    const { auth } = useAppContext();
    const { brother, loading } = useBrother(auth.user.uid);
    if(!auth.isLoaded || loading) {
        return null;
    }
    const missingBrotherProfileAlert = Object.keys(brother).length === 0 && (
        <Message severity='error' content={(
            <Link to='/brother/create'>
                <p>
                    You haven't yet created a Brother profile yet. This is required in
                    order to use Brother Search and other functions. Click on this message
                    to create your profile now.
                </p>
            </Link>
        )} />
    );
    const inProgressBrotherProfileAlert = Object.keys(brother).length > 0 && brother?.state === undefined && (
        <Message severity='warn' content={(
            <Link to='/brother/create'>
                <p>
                    You have an incomplete Brother profile. Click on this message to
                    continue with creation.
                </p>
            </Link>
        )} />
    );
    return (
        <MarginContainer>
            <div className='p-d-flex p-jc-center'>
                {missingBrotherProfileAlert}
                {inProgressBrotherProfileAlert}
            </div>
        </MarginContainer>
    );
}

export default DashboardAlerts