import { FC, useState } from 'react';

import { Card } from 'primereact/card';
import { TabView, TabPanel } from 'primereact/tabview';

import { useDocuments, Document } from 'context/DocumentContext';
import DocumentCard from 'components/dataview/document/DocumentCard';
import { dateInPast } from 'util/date/DateUtil';
import { useListLabels } from 'context/ConfigurationContext';
import { useAuth } from 'context/AuthContext';
import { useBrother } from 'context/BrotherContext';

const MyDocumentsDashboardCard: FC = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const docs: Document[] = useDocuments();
    const { userRole, user } = useAuth();
    const { brother, loading } = useBrother(user.uid);
    const roleLookup = useListLabels('roles');
    const locationLookup = useListLabels('locations');
    const myRole = roleLookup[userRole!];
    const myLocation = locationLookup[brother?.locationCrossed!!];
    const visibleToMyRole = docs.filter(doc => doc.accessType === 'role' && doc.access === 'admin');
    const visibleToMyLocation = docs.filter(doc => doc.accessType === 'location' && doc.access === brother.locationCrossed)
    return <>
        <Card title='My Documents'>
            <TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)}>
                <TabPanel header={`Visible to ${myRole}`}>
                    {visibleToMyRole.length > 0 ?
                        visibleToMyRole
                            .filter((_, idx) => idx <= 3)
                            .map(d => <DocumentCard key={d.id} {...d} />)
                        : <p>No Documents</p>}
                </TabPanel>
                <TabPanel header={`Visible to ${myLocation}`}>
                    {visibleToMyLocation.length > 0 ?
                        visibleToMyLocation
                            .filter((_, idx) => idx <= 3)
                            .map(d => <DocumentCard key={d.id} {...d} />)
                        : <p>No Documents</p>}
                </TabPanel>
            </TabView>
        </Card>
    </>;
}

export default MyDocumentsDashboardCard;