import { FC } from 'react';

import { useHistory } from 'react-router-dom';

import { Ripple } from 'primereact/ripple';

interface DashboardLinkCardProps {
    icon: any,
    title: string,
    href: string,
    className?: string,
    color: 'orange' | 'green' | 'white'
}
const DashboardLinkCard: FC<DashboardLinkCardProps> = ({
    icon, title, href, className, color,
}) => {
    const history = useHistory();
    const linkOCL = () => {
        setTimeout(() => {
            history.push(href);
        }, 500);
    };
    return <>
        <div onClick={linkOCL} className={`p-shadow-3 p-ripple ${color} ${className}`}
            style={{
                cursor: 'pointer', borderRadius: '3px', backgroundColor: 'var(--surface-50)',
                width: '10em', height: '10em', 
            }}>
            <div className='p-d-flex p-flex-column p-jc-center p-ai-center' style={{ display: 'flow-root', height: '100%', color: 'var(--text-color)' }}>
                <i className={`pi ${icon}`} style={{ fontSize: '1.75rem' }} />
                <h3 style={{ userSelect: 'none' }}>
                    {title}
                </h3>
            </div>
            <Ripple />
        </div>
    </>;
}

export default DashboardLinkCard;