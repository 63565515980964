import { Card } from "primereact/card";
import { Steps } from "primereact/steps";

import Workflow, { SimpleWorkflowState } from "workflows/components/Workflow";
import { ActionButton, ActionFormButton } from "workflows/components/Actions";
import { MarginContainer } from "components/layout/Container";
import InputField from "components/form/InputField";
import { useDocumentCreateController } from "controllers/document/DocumentCreateController";
import ConditionalField from "components/form/ConditionalField";
import { Document } from "context/DocumentContext";
import { FieldArray } from "formik";
import { Button } from "primereact/button";
import { useList } from "context/ConfigurationContext";
import { useBrotherSemestersList } from "context/BrotherContext";

const brotherCreateWorkflowModel = [
    { label: 'Start' },
    { label: 'Files' },
    { label: 'Submissions' },
    { label: 'Permissions' },
];

const validateStartState = (values: any) => {
    const errors: any = {};
    if (!values.name) {
        errors.name = 'Required';
    }
    return errors;
}

const validateFileUpload = (values: any) => {
    const errors: any = {};
    if (!values.files || values.files.length === 0) {
        errors.files = 'Required';
    }
    return errors;
}

const validateState1 = (values: any) => {
    const errors: any = {};
    if (values.acceptUserSubmissions) {
        if (!values.directions) {
            errors.directions = 'Required';
        }
        if (!values.owner) {
            errors.owner = 'Required';
        }
    }
    return errors;
}

const DocumentCreate = () => {
    const { doc, updateDoc, loading, NewDocumentForm, uploadFiles } = useDocumentCreateController();
    const locationList = useList('locations');
    const roleList = useList('roles');
    return <>
        <MarginContainer maxWidth='800px'>
            <Workflow initialState='start'>
                <SimpleWorkflowState state='start'>
                    <Card title='Welcome'>
                        <Steps model={brotherCreateWorkflowModel} activeIndex={0} />
                        <div className='p-px-3'>
                            <p>
                                This step-by-step guide will help create a new Document.
                            </p>
                            <p>
                                Let's start with the basics.
                            </p>
                            <NewDocumentForm validate={validateStartState}>
                                <InputField type='text' name='name' label='Document Name' disabled={loading} />
                                <InputField type='textarea' name='description' label='Description' disabled={loading} />
                                <div className='p-d-flex p-jc-around'>
                                    <ActionFormButton to='step1' label='Next' />
                                </div>
                            </NewDocumentForm>
                        </div>
                    </Card>
                </SimpleWorkflowState>
                <SimpleWorkflowState state='step1'>
                    <Card title='Files'>
                        <Steps model={brotherCreateWorkflowModel} activeIndex={1} />
                        <div className='p-px-3'>
                            <p>
                                Upload files to this document.
                            </p>
                            <NewDocumentForm validate={validateFileUpload}>
                                <InputField type='file' name='files' label='File Upload' disabled={loading} uploadHandler={async (e: any) => { await uploadFiles(e.files); }} />
                                {doc.files && <div className='p-my-4'>
                                    {doc.files!.map((f, i) => (
                                        <Card key={i} title={f.name} subTitle={f.type} className='p-shadow-3 p-mb-3'>
                                            <div className='p-d-flex p-jc-start'>
                                                <Button type='button' label='Download' className='p-mr-2 ' icon='pi pi-download' />
                                                <Button type='button' label='Remove' className='p-button-text' icon='pi pi-trash'
                                                    onClick={async () => { await updateDoc({ ...doc, files: doc.files?.filter((v, _i) => _i !== i) }); }} />
                                            </div>
                                        </Card>
                                    ))}
                                </div>}
                                <div className='p-d-flex p-jc-around p-flex-row-reverse'>
                                    <ActionFormButton to='step2' label='Next' />
                                    <ActionButton to='start' label='Go Back' className='p-button-text' />
                                </div>
                            </NewDocumentForm>
                        </div>
                    </Card>
                </SimpleWorkflowState>
                <SimpleWorkflowState state='step2'>
                    <Card title='User Submissions'>
                        <Steps model={brotherCreateWorkflowModel} activeIndex={2} />
                        <div className='p-px-3'>
                            <p>
                                Will this document accept user submissions?
                            </p>
                            <p>
                                If so, what directions should be shown? Who should be notified upon successful submission?
                            </p>
                            <NewDocumentForm validate={validateState1}>
                                <InputField type='checkbox' label='Accept User Submissions' name='acceptUserSubmissions' disabled={loading} />
                                <ConditionalField condition={(doc: Document) => !!doc.acceptUserSubmissions}>
                                    <InputField type='textarea' label='Submission Directions' name='directions' disabled={loading} />
                                    <InputField type='text' name='owner' label='Notify User/Group' disabled={loading} />
                                </ConditionalField>
                                <div className='p-d-flex p-jc-around p-flex-row-reverse'>
                                    <ActionFormButton to='step3' label='Next' />
                                    <ActionButton to='step1' label='Go Back' className='p-button-text' />
                                </div>
                            </NewDocumentForm>
                        </div>
                    </Card>
                </SimpleWorkflowState>
                <SimpleWorkflowState state='step3'>
                    <Card title='Document Permissions'>
                        <Steps model={brotherCreateWorkflowModel} activeIndex={3} />
                        <div className='p-px-3'>
                            <p>
                                Who should be able to see this document?
                            </p>
                            <NewDocumentForm>
                                <InputField type='listbox' label='Access Type' name='accessType' options={[
                                    { label: 'Everyone', value: 'any' },
                                    { label: 'Specific Role', value: 'role' },
                                    { label: 'Specific Location', value: 'location' },
                                    { label: 'Specific Group', value: 'group' },
                                ]} />
                                <ConditionalField condition={(doc: Document) => doc.accessType === 'role'}>
                                    <InputField type='dropdown' name='access' label='Role Access' disabled={loading}
                                        options={roleList} filter showClear/>
                                </ConditionalField>
                                <ConditionalField condition={(doc: Document) => doc.accessType === 'location'}>
                                    <InputField type='dropdown' name='access' label='Location Access' disabled={loading}
                                        options={locationList} filter showClear />
                                </ConditionalField>
                                <ConditionalField condition={(doc: Document) => doc.accessType === 'group'}>
                                    <InputField type='text' name='access' label='Group Access' disabled={loading} />
                                </ConditionalField>
                                <div className='p-d-flex p-jc-around p-flex-row-reverse'>
                                    <ActionFormButton to='confirm' label='Confirm' />
                                    <ActionButton to='step2' label='Go Back' className='p-button-text' />
                                </div>
                            </NewDocumentForm>
                        </div>
                    </Card>
                </SimpleWorkflowState>
                <SimpleWorkflowState state='confirm'>
                    <Card title='Confirmation'>
                        <Steps model={brotherCreateWorkflowModel} activeIndex={10} />
                        <div className='p-px-3'>
                            <p>
                                Your Document is ready for viewing.
                            </p>
                            <div className='p-d-flex p-jc-around p-flex-row-reverse'>
                                <ActionButton urlType='path' to={`/document/${doc?.id}`} label='Complete &amp; View' onClick={async () => {
                                    await updateDoc({ ...doc, state: 'public' });
                                }} />
                                <ActionButton to='step2' label='Go Back' className='p-button-text' />
                            </div>
                        </div>
                    </Card>
                </SimpleWorkflowState>
            </Workflow>
        </MarginContainer>
    </>
}

export default DocumentCreate;