import { FC } from 'react';

import { AppContext, withSecureContext } from 'context/AppContext';

import DashboardLinkCard from 'components/dashboard/common/DashboardLinkCard';
import MyDocumentsDashboardCard from 'components/dashboard/documents/MyDocumentsDashboardCard';
import MyLessonPlansDashboardCard from 'components/dashboard/lessons/MyLessonPlansDashboardCard';
import DashboardAlerts from 'components/dashboard/alerts/DashboardAlerts';

const DashboardQuickLinks: FC = () => {
    return <>
        <div className='p-d-flex p-flex-wrap p-jc-start p-my-2 p-mx-auto' style={{ width: 'fit-content' }}>
            <DashboardLinkCard href='/brother/search' title='Brother Search'
                color='orange' icon='pi-search' className='p-mr-3 p-mb-3' />
            <DashboardLinkCard href='/community' title='Community'
                color='green' icon='pi-comment' className='p-mr-3 p-mb-3' />
            <DashboardLinkCard href='/store' title='Shakti Store'
                color='white' icon='pi-shopping-cart' className='p-mb-3' />
        </div>
    </>;
}

const DashboardCardGrid: FC = () => {
    return <>
        <div className='p-grid p-mx-auto' style={{ maxWidth: '1000px' }}>
            <div className='p-col-12 p-md-12'>
                <MyDocumentsDashboardCard />
            </div>
            {/* <div className='p-col-12 p-md-6'>
                <MyLessonPlansDashboardCard />
            </div> */}
        </div>
    </>;
}

interface DashboardProps {
    context: AppContext
}
const Dashboard: FC<DashboardProps> = ({ context }) => {
    return <>
        <div className='p-mx-3 p-my-3' style={{ display: 'flow-root' }}>
            <DashboardAlerts />
            <DashboardQuickLinks />
            <DashboardCardGrid />
        </div>
    </>;
}

export default withSecureContext(Dashboard);