import { FC, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { ProgressSpinner } from 'primereact/progressspinner';

import { MattermostUser, useMattermostTeams, useMattermostUser } from 'context/MattermostContext';
import MattermostStore from 'context/stores/MattermostStore';
import { AppContext, withSecureContext } from 'context/AppContext';

interface MattermostCardProps {
    mattermost: MattermostStore,
    user?: MattermostUser,
}
const _MattermostAutoLoginCard: FC<MattermostCardProps> = ({ mattermost }) => {
    const { loginCookieExists, setLoginCookie, clearLoginCookie } = mattermost;
    if (loginCookieExists) {
        return (
            <Card title='IND Mattermost'>
                <p>Mattermost auto-login is ready.</p>
                <Button label='Logout' onClick={clearLoginCookie} />
            </Card>
        );
    }
    return (
        <Card title='IND Mattermost'>
            <p>Would you like to set up auto-login for Mattermost?</p>
            <Button label='Auto-Login' onClick={setLoginCookie} />
        </Card>
    );
}
const MattermostAutoLoginCard = observer(_MattermostAutoLoginCard);

interface CommunityDashboardProps {
    context: AppContext,
}
const Page: FC<CommunityDashboardProps> = ({ context }) => {
    const { user, loading: userLoading, error: userError } = useMattermostUser();
    const { teams, loading: teamsLoading, error: teamsError } = useMattermostTeams();
    const messagesRef = useRef<Messages>(null);
    const loading = teamsLoading || userLoading;
    const error = userError || teamsError;
    useEffect(() => {
        if(!loading && error) {
            const messages = [];
            if(error) {
                messages.push({
                    severity: 'error', sticky: true, content: (
                        'An error occured while attempting to retreive user information. Please contact an administator for assistance connecting to Mattermost.'
                    )
                });
            }
            messagesRef.current?.show(messages);
        }
    }, [error, loading]);
    return <>
        <div className='p-mx-auto p-px-3 p-my-3' style={{ display: 'flow-root', maxWidth: '1000px' }}>
            {loading ? (
                <div className='p-d-flex p-jc-center p-ai-center' style={{ height: '100%' }}>
                    <ProgressSpinner />
                </div>
            ) : <>
                {error ? <>
                    <Card title='IND Mattermost'>
                        <Messages ref={messagesRef} />
                    </Card>
                </> : <>
                    <MattermostAutoLoginCard mattermost={context.mattermost} user={user} />
                    <div className='p-grid p-mt-2'>
                        {teams?.map(t => (
                            <div className='p-col-12 p-md-4' key={t.id}>
                                <a href={`${context.mattermost.mattermostUrl}/${t.name}`} target='_blank' rel='noreferrer'
                                    style={{ textDecoration: 'none', color: 'unset' }}>
                                    <Card title={t.display_name} className='p-mr-2 p-mb-2'>
                                        {t.description}
                                    </Card>
                                </a>
                            </div>
                        ))}
                    </div>
                </>}
            </>}
        </div>
    </>;
}

export default withSecureContext(Page);