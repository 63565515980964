import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/performance';
import 'firebase/storage';
import 'firebase/auth';

export type Timestamp = firebase.firestore.Timestamp|any;

export const firebaseConfig = {
    apiKey: "AIzaSyDSA96AIZj0SOk3_wLqv3hY2yPi03ux61Y",
    authDomain: "ind-internal-dev.firebaseapp.com",
    databaseURL: "https://ind-internal-dev.firebaseio.com",
    projectId: "ind-internal-dev",
    storageBucket: "ind-internal-dev.appspot.com",
    messagingSenderId: "281297958363",
    appId: "1:281297958363:web:d03a88243f735f03ece3fe",
    measurementId: "G-BQRWWED656",
};
export const firebaseApp = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
export const useFirestore = () => firebase.firestore();
export const useAuth = () => firebaseApp.auth();
export const useGoogleAuthProvider = () => new firebase.auth.GoogleAuthProvider();
export const useAnalytics = () => firebaseApp.analytics();
export const useFieldValue = () => firebase.firestore.FieldValue;
export const useTimestamp = () => firebase.firestore.Timestamp;

export function dateFromTimestamp(ts: Timestamp) {
    const seconds = ts._seconds || ts.seconds;
    return new Date(seconds * 1000);
}

export function timestampFromDate(date: Date) {
    return firebase.firestore.Timestamp.fromDate(date);
}

//only load if window object is available (e.g. only on client)
//can be removed to decrease package size
export const firebasePerformance = (typeof window !== 'undefined') && firebaseApp.performance();
