import { useMemo } from 'react';

import algoliasearch from 'algoliasearch/lite';

export const client = algoliasearch('Q2PLTJJERC', 'bb037f99ef2ef7dd5be2f07da91775e1');

export const useAlgoliaIndex = (indexName: string) => {
    return useMemo(() => {
        return client.initIndex(indexName);
    }, [indexName]);
};