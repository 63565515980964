import { History } from 'history';

import { AuthenticationError } from 'services/Errors';

import AuthenticationStore from './AuthenticationStore';
import BrothersStore from './BrothersStore';
import ConfigurationStore from './ConfigurationStore';
import PreferenceStore from './PreferenceStore';
import BrotherNotificationStore from './NotificationStore';
import MattermostConfigStore from './MattermostStore';
import DocumentStore from './DocumentStore';
import FileStorageStore from './FileStorageStore';

class RootStore {
    serviceName: string = 'RootStore';
    history: History | null = null;

    auth = new AuthenticationStore(this);
    preferences = new PreferenceStore(this);
    brothers = new BrothersStore(this);
    config = new ConfigurationStore(this);
    mattermost = new MattermostConfigStore(this);
    notifications = new BrotherNotificationStore(this);
    documents = new DocumentStore(this);
    storage = new FileStorageStore(this);

    // react-router history and related methods
    changeRoute = (newRoute: string) => {
        if (this.history) {
            this.history.push(newRoute);
        }
    };
    goBack = () => {
        if (this.history) {
            this.history.goBack();
        }
    };
    
    // api-call convinience method
    apiCall = (endpoint: string, options: {[key: string]: any} = {}) => {
        const url = `https://dev.brothers.iotanudelta.org/api/v1${endpoint}`;
        const idToken = this.auth.idToken;
        if (!idToken) {
            throw new AuthenticationError(this.serviceName, 'no-token', `A secure request was attempted but no identity tokens could be accessed by ${this.serviceName}.`)
        }
        const headers = options.headers || {};
        headers['Authorization'] = `Bearer ${idToken}`;
        options.headers = headers;
        return fetch(url, options);
    };

    publicApiCall = (endpoint: string, options: {[key: string]: any} = {}) => {
        const url = `https://dev.brothers.iotanudelta.org/api/public/v1${endpoint}`;
        const idToken = this.auth.idToken;
        if (!idToken) {
            throw new AuthenticationError(this.serviceName, 'no-token', `A secure request was attempted but no identity tokens could be accessed by ${this.serviceName}.`)
        }
        const headers = options.headers || {};
        headers['Authorization'] = `Bearer ${idToken}`;
        options.headers = headers;
        return fetch(url, options);
    };
}

export default RootStore;