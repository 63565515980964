import { FC, FormEvent, useMemo, useState } from 'react';

import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { DataView } from 'primereact/dataview';
import { Divider } from 'primereact/divider';
import { Chip } from 'primereact/chip';
import { ProgressSpinner } from 'primereact/progressspinner';

import { useList, useListLabels } from 'context/ConfigurationContext';
import { useSearch, useSearchParams } from 'hooks/search/SearchHooks';
import BrotherSearchResult from 'components/dataview/brother/BrotherSearchDataView';

function getOrDefault(params: URLSearchParams, key: string, defaultValue: string = ''): string {
    if (params.has(key)) {
        const value = params.get(key);
        if (value !== null) {
            return value;
        }
    }
    return defaultValue;
}

const sortOptions = [
    { label: 'Name (Asc)', value: 'realName' },
    { label: 'Name (Des)', value: '!realName' },
    { label: 'Brother Name (Asc)', value: 'brotherName' },
    { label: 'Brother Name (Des)', value: '!brotherName' },
];

const Page: FC = () => {
    const locationsList = useList('locations');
    const locationsMap = useListLabels('locations');
    // get url search params
    const { searchParams } = useSearchParams();
    // location filter
    const defaultLocationFilter = getOrDefault(searchParams, 'locationCrossed', '');
    const [appliedLocationFilter, setAppliedLocationFilter] = useState<string>(defaultLocationFilter);
    const [immediateLocationFilter, setImmediateLocationFilter] = useState<string>(defaultLocationFilter);
    // package search filters
    const filters = useMemo(() => [
        {
            label: 'Location', key: 'locationCrossed',
            value: appliedLocationFilter,
            displayValue: locationsMap ? locationsMap[appliedLocationFilter] : '',
            reset: () => {
                setAppliedLocationFilter('');
                setImmediateLocationFilter('');
            }
        },
    ], [locationsMap, appliedLocationFilter]);
    // use search states and hooks
    const {
        searchResultsHeader, itemTemplate,
        queryStates, sortStates, appliedFilters,
        results, layout, loading, search,
    } = useSearch('brothers', BrotherSearchResult, sortOptions, filters);
    // setup search states
    const {
        immediate: [immediateQuery, setImmediateQuery],
        applied: [, setAppliedQuery],
    } = queryStates;
    const {
        field: [sortField,],
        order: [sortOrder,],
    } = sortStates;
    const searchBrothersOSL = async (e: FormEvent) => {
        e.preventDefault();
        setAppliedQuery(immediateQuery);
        setAppliedLocationFilter(immediateLocationFilter);
        search();
    };
    return <>
        <div className='p-mx-auto p-px-3 p-my-3' style={{ display: 'flow-root', maxWidth: '1300px' }}>
            <div className='p-grid'>
                <div className='p-col-12 p-lg-4'>
                    <form onSubmit={searchBrothersOSL}>
                        <Card title='Brother Search' className='p-mb-2'>
                            <div className='p-fluid'>
                                <div className='p-field p-d-flex'>
                                    <InputText value={immediateQuery} onChange={e => setImmediateQuery(e.target.value)}
                                        id='search_input' className='p-inputtext-lg p-d-block' placeholder='Search' />
                                    <Button icon='pi pi-search' className='p-ml-1' style={{ width: '6ch' }} disabled={loading} />
                                </div>
                            </div>
                            <div className='p-d-flex p-flex-wrap p-my-3'>
                                {appliedFilters.map(({ label, key, displayValue, reset }) => (
                                    <Chip key={key} label={displayValue}
                                        removable onRemove={reset} className='p-mr-2 p-mb-2 primary-bg' />
                                ))}
                            </div>
                            <Divider>
                                <div className="p-d-inline-flex p-ai-center">
                                    <i className="pi pi-filter p-mr-2"></i>
                                    <b>Filters</b>
                                </div>
                            </Divider>
                            <div className='p-fluid'>
                                <div className='p-field'>
                                    <Dropdown options={locationsList} optionLabel='label' optionValue='value' placeholder='Filter by Location'
                                        value={immediateLocationFilter} onChange={e => setImmediateLocationFilter(e.value)} />
                                </div>
                            </div>
                        </Card>
                    </form>
                </div>
                <div className='p-col-12 p-lg-8'>
                    <div className='p-shadow-2' style={{ backgroundColor: 'var(--surface-100)', borderRadius: '4px', overflow: 'hidden' }}>
                        {loading ? (
                            <div className='p-d-flex p-jc-center p-ai-center' style={{ height: '100%' }}>
                                <ProgressSpinner />
                            </div>
                        ) : (
                            <DataView value={results} layout={layout}
                                header={searchResultsHeader}
                                itemTemplate={itemTemplate}
                                sortField={sortField} sortOrder={sortOrder}
                                paginator rows={6} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default Page;